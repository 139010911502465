<template>
  <v-container fluid class="layout-content ma-auto w-full">
    <div id="login">
      <v-card class="text-center pa-2" elevation="0">
        <car-trust-header></car-trust-header>
        <v-row align="center" class="agent--text mx-auto" justify="center">
          <v-col class="agent--text text-center" cols="12" tag="h1">
            <!-- <span
              :class="[
                $vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2',
              ]"
              class="font-weight-light"
            >
              WELCOME
            </span>

            <br /> -->

            <span
              :class="[
                $vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4',
              ]"
              class="font-weight-black"
            >
              AGENT
            </span>
          </v-col>
        </v-row>
        <v-card-text>
          <v-container>
            <v-form ref="form">
              <v-row>
                <v-col
                  lg="4"
                  offset-lg="4"
                  offset-md="3"
                  md="6"
                  offset-sm="3"
                  sm="6"
                  cols="12"
                  :align-self="'center'"
                >
                  <v-text-field
                    v-model="signInUser.username"
                    :rules="[rules.required]"
                    :validate-on-blur="false"
                    :error="error"
                    label="Username"
                    name="username"
                    outlined
                    @keyup.enter="submit"
                    @change="resetErrors"
                  ></v-text-field>
                </v-col>
                <v-col
                  lg="4"
                  offset-lg="4"
                  offset-md="3"
                  md="6"
                  offset-sm="3"
                  sm="6"
                  cols="12"
                  :align-self="'center'"
                >
                  <v-text-field
                    v-model="signInUser.password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required]"
                    :type="showPassword ? 'text' : 'password'"
                    :error="error"
                    :error-messages="errorMessages"
                    :label="'Password'"
                    name="password"
                    class="mb-2"
                    outlined
                    @change="resetErrors"
                    @keyup.enter="submit"
                    @click:append="showPassword = !showPassword"
                    @click.right.prevent
                    @copy.prevent
                  ></v-text-field>
                </v-col>
                <v-col
                  offset-lg="4"
                  lg="4"
                  offset-md="3"
                  md="6"
                  offset-sm="3"
                  sm="6"
                  :align-self="'center'"
                >
                  <v-btn
                    :loading="isLoading"
                    :disabled="isSignInDisabled"
                    block
                    x-large
                    color="primary"
                    style="height: 47px"
                    @click="submit"
                    >Log In</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  components: {
    CarTrustHeader: () => import("./CarTrustHeader"),
  },
  data() {
    return {
      // sign in buttons
      isLoading: false,
      isSignInDisabled: false,

      // form
      isFormValid: true,

      signInUser: {
        username: "",
        password: "",
        remember: 0,
        reference: "",
      },

      // form error
      error: false,
      errorMessages: "",

      errorProvider: false,
      errorProviderMessages: "",

      // show password field
      showPassword: false,

      // input rules
      rules: {
        required: (value) => (value && Boolean(value)) || "Required",
      },
    };
  },
  methods: {
    ...mapActions("agent", ["signIn"]),
    submit() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        this.isSignInDisabled = true;

        this.signIn(this.signInUser)
          .then((response) => {
            console.log("check:", response);
            this.$router.push({ name: "new-lead" });
          })
          .catch((error) => {
            console.log(Object.keys(error));
            console.log("Login error", error.response);
            this.errorMessages =
              error.response.data.response.detail || "Cannot sign in";
            this.error = true;
            this.isLoading = false;
            this.isSignInDisabled = false;
          });
      }
    },
    resetErrors() {
      this.error = false;
      this.errorMessages = "";
      this.errorProvider = false;
      this.errorProviderMessages = "";
    },
  },
};
</script>

<style></style>
